import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userRole: null,
  accessList: [],
  accessToken: '',
  user: {
    name: '',
    designation: '',
    imagePath: '',
  },
  currentLanguage: {},
  dashboardActive: '',
  franchiseId: '',
  franchiseTimezone: '',
  siteId: '',
  siteName: '',
  toggleNotifications: false,
  notificationsCount: 0,
  saasToken: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserRole(state, { payload: userRole }) {
      state.userRole = userRole;
    },
    setUserAccessList(state, { payload: accessList }) {
      state.accessList = accessList;
    },
    setAccessToken(state, { payload: accessToken }) {
      state.accessToken = accessToken;
    },
    setCurrentLanguage(state, { payload: currentLanguage }) {
      state.currentLanguage = currentLanguage;
    },
    setDashboardActive(state, { payload: dashboardActive }) {
      state.dashboardActive = dashboardActive;
    },
    setFranchiseId(state, { payload: franchiseId }) {
      state.franchiseId = franchiseId;
    },
    setSiteId(state, { payload: siteId }) {
      state.siteId = siteId;
    },
    setSiteName(state, { payload: siteName }) {
      state.siteName = siteName;
    },
    toggleNotificationReceived(state) {
      state.toggleNotifications = !state.toggleNotifications;
    },
    setNotificationsCountRedux(state, { payload }) {
      state.notificationsCount = payload;
    },
    setSaasToken(state, { payload }) {
      state.saasToken = payload;
    },
    setFranchiseTimezone(state, { payload: franchiseTimezone }) {
      state.franchiseTimezone = franchiseTimezone;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: {},
});

export const {
  setUserRole,
  setUserAccessList,
  setAccessToken,
  setCurrentLanguage,
  setDashboardActive,
  setFranchiseId,
  setNotificationsCountRedux,
  toggleNotificationReceived,
  setSiteName,
  setSaasToken,
  setFranchiseTimezone,
} = authSlice.actions;

export default authSlice.reducer;
