import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { setDateToTime } from 'src/helper/utilityFunctions';
import store from 'src/redux/store/index';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getUTCHoursAndMinutesWithCurrentDate = (fromDate, toDate) => {
  return dayjs
    .utc(toDate)
    .hour(dayjs.utc(fromDate).get('h'))
    .minute(dayjs.utc(fromDate).get('m'))
    .second(0)
    .millisecond(0);
};

export const getHoursAndMinutesWithCurrentDate = (toDate, fromDate) => {
  // if fromDate is undefined, then dayjs will pick current date.

  const specificDate = dayjs(toDate).utc();
  const updatedDate = specificDate
    .year(dayjs(fromDate).year())
    .month(dayjs(fromDate).month())
    .date(dayjs(fromDate).date())
    .second(0)
    .millisecond(0)
    .toISOString();
  return dayjs(updatedDate);

  // return dayjs(toDate)
  //   .hour(dayjs(fromDate).get('h'))
  //   .minute(dayjs(fromDate).get('m'))
  //   .second(0)
  //   .millisecond(0);
};

// params are in dayjs format
export const getStartEndTimeWithDesiredDate = (date, startTime, endTime) => {
  if (!date || !startTime || !endTime) return {};

  startTime = getHoursAndMinutesWithCurrentDate(startTime);
  endTime = getHoursAndMinutesWithCurrentDate(endTime);

  const updatedStartTime = setDateToTime({
    fromValue: date?.toISOString(),
    toValue: startTime?.toISOString(),
  });

  // check if end time is less than or equal to start time
  const isNextDate = endTime.isBefore(startTime) || endTime.isSame(startTime);

  const updatedDate = isNextDate
    ? dayjs(updatedStartTime).set('date', dayjs(updatedStartTime).get('date') + 1) // add 1 date to updated start date
    : dayjs(updatedStartTime);

  const updatedEndTime = setDateToTime({
    fromValue: updatedDate?.toISOString(),
    toValue: endTime?.toISOString(),
  });

  return { startTime: updatedStartTime, endTime: updatedEndTime, isEndTimeOnNextDate: isNextDate };
};

export const getHoursDiff = (startsAt, endsAt) => {
  const { startTime, endTime } = getStartEndTimeWithDesiredDate(
    dayjs(),
    dayjs(startsAt),
    dayjs(endsAt),
  );
  return dayjs(endTime).diff(dayjs(startTime), 'h', true);
};

export const getHoursDiff24HourFormat = (startsAt, endsAt) => {
  const timeDiff = getHoursDiff(startsAt, endsAt);
  const totalHours = timeDiff < 0 ? 24 + timeDiff : timeDiff === 0 ? 24 : timeDiff;

  return totalHours;
};

const getAlphabetAscii = (input) => 65 + (input % 26); // input can only range from 0 - 26
export const getSplittedShiftName = (t, shiftNumber, input) => {
  let alphabetsAscii = getAlphabetAscii(input);
  let alphabetStr = String.fromCharCode(alphabetsAscii);

  const noOfAsciiIterations = Math.floor(input / 26);
  const prefixAlphabet = String.fromCharCode(getAlphabetAscii(noOfAsciiIterations));

  for (let i = 0; i < noOfAsciiIterations; i++) {
    alphabetStr = prefixAlphabet + alphabetStr;
  }

  return t('obx.schedules.assignDedicatedDuty.splitDuties.defaultShiftName', {
    index: `${shiftNumber}-${alphabetStr}`,
  });
};

export const selectDayNumber = (day) => {
  return day === -1 ? 6 : day === 7 ? 0 : day;
};

export const getDaysWrtTimezone = (startTime, shiftDays, isIso) => {
  // if isIso is true, then it means convert days WRT. ISO time otherwise convert days to local time

  const timezoneOffset = new Date().getTimezoneOffset();
  const isoDate = dayjs(startTime).add(timezoneOffset, 'minute');
  const localDate = dayjs(startTime);

  // if local date is at next date than ISO date
  if (localDate.isAfter(isoDate, 'date')) {
    return shiftDays?.map((shiftDay) => {
      // for ISO time, subtract 1 day from shift day. For Local time, add 1 day to shift day.
      const day = isIso ? shiftDay - 1 : shiftDay + 1;
      return selectDayNumber(day);
    });
  }

  // if local date is at previous date than ISO date
  if (localDate.isBefore(isoDate, 'date')) {
    return shiftDays?.map((shiftDay) => {
      // for ISO time, add 1 day to shift day. For Local time, subtract 1 day from shift day.
      const day = isIso ? shiftDay + 1 : shiftDay - 1;
      return selectDayNumber(day);
    });
  }

  return shiftDays;
};

export const getTimezone = () => {
  const franchiseTimeZoneObj = getStoredTimeZoneValue();
  const timezone = franchiseTimeZoneObj.franchiseTimeZone || dayjs.tz.guess();

  return timezone;
};

export const dayjsWithTimezone = (date) => dayjs.tz(date, getTimezone());

export const getOffsetWithStandardTime = () => {
  // '2024-01-01' -- this date will always contains standard offset and not DLS(daylight-saving) offset even for timezones where DLS gets enabled
  return dayjsWithTimezone('2024-01-01').utcOffset();
};

export const checkDLSWrtDate = (date) => {
  // if date is undefined, it means we are picking current date and time
  return {
    isDLSWrtDate: getOffsetWithStandardTime() !== dayjsWithTimezone(date).utcOffset(),
    offsetDiff: getOffsetWithStandardTime() - dayjsWithTimezone(date).utcOffset(), // offsetDiff will be negative if DLS is enabled and current timezone is at negative offset and vice versa
  };
};

export const adjustHourForTimePopulationWrtCurrentDate = (dateTimeToPopulate) => {
  const { offsetDiff: offsetDiffWRTCurrentDate } = checkDLSWrtDate();
  return dayjs(dateTimeToPopulate).add(offsetDiffWRTCurrentDate, 'minute');
};

export const adjustHourForTimePayloadInIso = (dateTime) => {
  const { offsetDiff: offsetDiffWRTCurrentDate } = checkDLSWrtDate();
  const dateTimeInIso = dayjs(dateTime)?.toISOString();
  return dayjs(dateTimeInIso).add(-offsetDiffWRTCurrentDate, 'minute').toISOString();
};

export const dayjsWithStandardOffset = (date, keepLocalTime) => {
  const standardOffset = getOffsetWithStandardTime();
  if (!dayjs(date).isValid()) {
    date = undefined;
  }

  const utcDate = dayjs(date)?.toISOString();
  return dayjs(utcDate).utcOffset(standardOffset, !!keepLocalTime);
};

export const getStoredTimeZoneValue = () => {
  // grab current state
  const state = store.getState();

  const stateAuth = state?.auth;
  return {
    role: stateAuth?.userRole?.slug,
    franchiseTimeZone: stateAuth?.franchiseTimezone,
  };
};

export const timeFormat12h = (value, formatWRTStandardTime) => {
  value = formatWRTStandardTime ? dayjsWithStandardOffset(value) : dayjs(value);
  const minutes = value.minute();
  const timeFormat = minutes === 0 ? 'ha' : 'h:mma';
  return value ? value.format(timeFormat).replace('m', '') : '';
};
