import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  siteInfo: null,
  siteId: null,
  shiftId: null,
};

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSiteInfoData(state, { payload: siteData }) {
      state.siteInfo = siteData;
    },
    setSiteId(state, { payload: siteId }) {
      state.siteId = siteId;
    },
    setShiftId(state, { payload: shiftId }) {
      state.shiftId = shiftId;
    },
  },
});

export const { setSiteInfoData, setSiteId, setShiftId } = siteSlice.actions;

export default siteSlice.reducer;
