import { createTheme } from '@mui/material/styles';

import breakpoints from './breakpoints';
import darkThemeConfig from './dark/theme';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const baseTheme = {
  palette,
  typography: typography(palette),
  overrides: overrides({ palette }),
  breakpoints: breakpoints,
};

export const theme = createTheme({
  ...baseTheme,
  components: {
    MuiButton: baseTheme.overrides.MuiButton,
    MuiOutlinedInput: baseTheme.overrides.MuiTextField,
    MuiSwitch: baseTheme.overrides.MuiSwitch,
    MuiRadio: baseTheme.overrides.MuiRadio,
    MuiCheckbox: baseTheme.overrides.MuiCheckbox,
    MuiInputLabel: baseTheme.overrides.MuiInputLabel,
    MuiTableCell: baseTheme.overrides.MuiTableCell,
    MuiChip: baseTheme.overrides.MuiChip,
    MuiSelect: baseTheme.overrides.MuiSelect,
    MuiMenuItem: baseTheme.overrides.MuiMenuItem,
    MuiTooltip: baseTheme.overrides.MuiTooltip,
    MuiAccordion: baseTheme.overrides.MuiAccordion,
    MuiSkeleton: baseTheme.overrides.MuiSkeleton,
    MuiList: baseTheme.overrides.MuiList,
  },
});

export const themeDark = createTheme({
  ...darkThemeConfig,
  components: {
    MuiButton: darkThemeConfig.overrides.MuiButton,
  },
});
