import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: null,
  currentLanguage: null,
};

export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfoData(state, { payload: userData }) {
      state.info = userData;
    },
  },
});

export const { setInfoData } = authSlice.actions;

export default authSlice.reducer;
