import { lazy } from 'react';

import withSuspense from '../../../hoc/withSuspense';
import validateAuthState from '../../../utils/auth/validateAuthState';
import {
  APP,
  FORGOT_PASSWORD,
  LOGIN,
  LOGOUT,
  NO_INTERNET,
  NO_SERVER,
  RESET_PASSWORD,
  ROOT,
  VISITOR_MANAGEMENT,
} from '../constant/ROUTE';

// Login
const Login = lazy(() => import(/* webpackChunkName: "Login" */ '../../public/pages/login/login'));
const LoginWithSuspense = withSuspense(Login);

// Logout
const Logout = lazy(
  () => import(/* webpackChunkName: "Login" */ '../../public/pages/logout/index'),
);
const LogoutWithSuspense = withSuspense(Logout);

// Home Dev Page
// const DevLogin = lazy(
//   () => import(/* webpackChunkName: "Home" */ '../../public/pages/login/devLogin'),
// );
// const DevLoginWithSuspense = withSuspense(DevLogin);

// // Forgot Password
const ForgotPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "forgotPassword" */ '../../public/pages/forgetPassword/forgetPassword'
    ),
);
const ForgotPasswordWithSuspense = withSuspense(ForgotPassword);

// // Reset Password
const ResetPassword = lazy(
  () =>
    import(
      /* webpackChunkName: "changePassword" */ '../../public/pages/changePassword/changePassword'
    ),
);
const ResetPasswordWithSuspense = withSuspense(ResetPassword);

// No Server
const NoServer = lazy(
  () => import(/* webpackChunkName: "NoServer" */ '../../public/pages/noServer'),
);
const NoServerWithSuspense = withSuspense(NoServer);
// No Internet
const NoInternet = lazy(
  () => import(/* webpackChunkName: "NoServer" */ '../../public/pages/noInternet'),
);
const NoInternetWithSuspense = withSuspense(NoInternet);

// // App Main
const AppMain = lazy(() => import(/* webpackChunkName: "AppMain" */ '../../layout/appMain'));
const AppMainWithSuspense = withSuspense(AppMain);

// Before enter callback for all auth routes
export const authCheckMiddleware = (next) => {
  const isAuthenticated = validateAuthState();
  if (isAuthenticated) {
    return next(VISITOR_MANAGEMENT);
  } else {
    return next();
  }
};

// Route configurations for settings
function getRouteConfigs() {
  return [
    {
      // LOGIN
      path: LOGIN,
      exact: true,
      element: <LoginWithSuspense />,
      // beforeEnter: authCheckMiddleware,
      meta: {
        title: 'Login',
      },
    },
    // {
    //   // LOGIN
    //   path: DevLOGIN,
    //   exact: true,
    //   element: <DevLoginWithSuspense />,
    //   // beforeEnter: authCheckMiddleware,
    //   meta: {
    //     title: 'Login',
    //   },
    // },
    {
      // FORGOT PASSWORD
      path: FORGOT_PASSWORD,
      exact: true,
      element: <ForgotPasswordWithSuspense />,
      beforeEnter: authCheckMiddleware,
      meta: {
        title: 'Forgot Password',
      },
    },
    {
      // RESET PASSWORD
      path: RESET_PASSWORD,
      exact: true,
      element: <ResetPasswordWithSuspense />,
      beforeEnter: authCheckMiddleware,
      meta: {
        title: 'Reset Password',
      },
    },
    {
      // LOGOUT
      path: LOGOUT,
      exact: true,
      element: <LogoutWithSuspense />,
      // beforeEnter: authCheckMiddleware,
      meta: {
        title: 'Logout',
      },
    },
    {
      // Problem Reported Details
      path: NO_SERVER,
      exact: true,
      element: <NoServerWithSuspense />,
      // beforeEnter: authCheckMiddleware,
      meta: {
        title: 'No Server',
      },
    },
    {
      // Problem Reported Details
      path: NO_INTERNET,
      exact: true,
      element: <NoInternetWithSuspense />,
      // beforeEnter: authCheckMiddleware,
      meta: {
        title: 'No Internet',
      },
    },
    {
      // PUBLIC MAIN
      path: APP,
      element: <AppMainWithSuspense />,
    },
    {
      // NOT FOUND ROUTE
      path: '*',
      redirect: ROOT,
    },
  ];
}

export default getRouteConfigs;
