import { getHttpRequest, postHttpRequest } from '../helper/axios';
import { throwAPIError } from '../utils/throwAPIError';

export const TEMPLATE_SERVICE = process.env.REACT_APP_TEMPLATE;
export const ROUTING_SERVICE = process.env.REACT_APP_ROUTING;

//Detail
export async function getTemplate(templateId) {
  try {
    const data = await getHttpRequest(`${TEMPLATE_SERVICE}/templates/${templateId}`);
    return data;
  } catch (e) {
    return throwAPIError(e);
  }
}

export const getUploadGenerationToken = async (body) => {
  try {
    return await postHttpRequest(`${ROUTING_SERVICE}/storage-token/generateToken`, body);
  } catch (e) {
    return throwAPIError(e);
  }
};
